import React, { createContext, useState } from "react";
import { PaymentPreference } from "./PaymentPrefence";
import SettlementPreferenceNav from "./SettlementPrefenceNav";
import { SettlementPrefenceTable } from "./SettlementPreferenceTable";
import Layout from '../../../../../components/shared/Layout';
import { TerminalPrefenceTable } from "./TerminalSettlementPreference";

export const SettlementContext = createContext({
  route: "waya",
});
export const SettlementPreference = () => {
  const [state, setState] = useState({
    route: "waya",
  });

  const { route } = state || {};
  const renderPages = () => {
    switch (route) {
      case "waya":
        return <SettlementPrefenceTable />;
      case "auth":
        return <PaymentPreference />;
      case "settlement":
        return <TerminalPrefenceTable />;
      default:
        return <SettlementPrefenceTable />;
    }
  };

  const onRouteChange = (value) => {
    setState((state) => ({
      ...state,
      route: value,
    }));
  };
  return (
    <Layout>
      <SettlementContext.Provider
        value={{
          onRouteChange,
          ...state,
          setState,
        }}
      >
        <SettlementPreferenceNav />
        {renderPages()}
      </SettlementContext.Provider>
    </Layout>
  );
};
