import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPut,
} from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';
import LinkBvn from '../../shared/Modals/Bvn';
import PinModal from '../../shared/Modals/PinModal';
import Button from '../../shared/Button';
import moment from 'moment';
import validateImage from '../../../utils/validateImage';
import { hideLoader, showLoader } from '../../../utils/loader';
import Swal from 'sweetalert2';
import check from '../../../assets/image/check.svg';
import pending from '../../../assets/image/pending.svg';
import rejected from '../../../assets/image/rejected.svg';
import { notification } from 'antd';
import ViewDocModal from './viewDoc';

const initial = {
  address: '',
  bvn: '',
  dob: '',
  email: '',
  firstName: '',
  // idCard: false,
  // idCardType: '',
  // idCardUrl: '',
  lastName: '',
  lga: '',
  nationality: '',
  // nextOfKinAddress: '',
  // nextOfKinEmail: '',
  // nextOfKinFirstName: '',
  // nextOfKinLastName: '',
  // nextOfKinPhone: '',
  otherName: '',
  ownershipCut: 0,
  // passport: false,
  // passportUrl: '',
  phone: '',
  state: '',
  // utilityBill: false,
  // utilityBillUrl: '',
  idCardType: 'NIN',
};
export default function UploadDirectors(props) {
  const {
    directors,
    setSPage,
    sPage,
    sData,
    userId,
    businessId,
    getDirectors,
    documents,
    getDocuments,
  } = props;
  const [data, setData] = useState(initial);
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendVerifyEmailOtp, setResendVerifyEmailOtp] = useState(false);
  const [nibssUrl, setNibssUrl] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [isNew, setIsNew] = useState(true);
  const [files, setFiles] = useState({});
  const [viewData, setViewData] = useState({});
  const [dirStatus, setDirStatus] = useState({});
  const [viewDocModal, setShowViewDocModal] = useState(false);
  const [idStatus, setIdStatus] = useState('');
  const [directorId, setDirectorId] = useState(null);
  const [reload, setReload] = useState(false);
  const {
    // profile: { firstName, surname, dateOfBirth, email, phoneNumber },
    userDetails,
    profile: { firstName, surname, email: uEmail },
    profile,
  } = useContext(ProfileContext);
  const handleSave = async (e) => {
    e.preventDefault();
    const profileId = localStorage.getItem('profileId');
    let res;
    const updateData = {
      passport: data?.passport,
      passportUrl: data?.passportUrl,
      utilityBill: data?.utilityBill,
      utilityBillUrl: data?.utilityBillUrl,
    };
    showLoader();
    if (!isNew) {
      res = await httpPut(
        `/api/v1/kyc/cac/updateBusinessDirector/${directorId}`,
        data,
        customBaseUrl.kycUrl
      );
    } else {
      res = await httpPost(
        `/api/v1/kyc/cac/createBusinessDirector/${businessId}/${userId}/${profileId}`,
        data,
        customBaseUrl.kycUrl
      );
    }
    if (res?.status) {
      hideLoader();
      approvalNoti();
      getDirectors();
      getDocuments();
      Swal.fire('Saved', res?.message);
      setReload(!reload);
    } else {
      hideLoader();
      Swal.fire(' ', res?.message);
    }
  };


  const approvalNoti = async () => {
    showLoader();
    const data = {
      attachment: 'string',
      data: {
        message: `Director documents has been submitted for approval`,
        names: [
          {
            email: process.env.KYC_EMAIL ?? 'staging.wayaadmin@wayapaychat.com',
            fullName: 'Waya Admin',
          },
        ],
      },
      eventCategory: 'WELCOME',
      eventType: 'EMAIL',
      htmlCode: `<p>Email: ${uEmail}</p>
      <p>BusinessName: ${profile?.otherDetails?.organisationName}</p>`,
      initiator: firstName,
      productType: 'WAYABANK',
      subject: `Document submitted for verification`,
    };
    const res = await httpPost(`/email-notification`,
      data,
      customBaseUrl.notificationUrl
    );
    if (res.status === true) {
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const validateId = async () => {
    if (data?.idNumber && data?.idNumber.length < 3) return;
    showLoader();
    let res;
    if (data?.idCardType === 'INTERNATIONAL_PASSPORT') {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/INTERNATIONAL_PASSPORT?lastName=${data?.lastName}&regNo=${data?.idNumber}`,
        {},
        customBaseUrl.kycUrl
      );
    } else if (data?.idCardType === 'NIN') {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/NIN?regNo=${data?.idNumber}`,
        {},
        customBaseUrl.kycUrl
      );
    } else if (data?.idCardType === 'DRIVER_LICENCE') {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/driverLicence?dob=${data?.dob}&firstName=${data?.firstName}&lastName=${data?.lastName}&regNo=${data?.idNumber}`,
        {},
        customBaseUrl.kycUrl
      );
    } else {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/VOTERS_CARD?dob=${data?.dob}&firstName=${data?.firstName}&lastName=${data?.lastName}&lga=${data?.lga}&regNo=${data?.idNumber}&state=${data?.state}`,
        {},
        customBaseUrl.kycUrl
      );
    }
    if (res?.status) {
      hideLoader();
      notification.success({
        message: 'successful',
        description: `${res?.message}`,
      });
      setData({ ...data, idNumber: data?.idNumber, idApproved: true });
      //  setIdStatus('valid ID');
      return true;
    } else {
      hideLoader();
      notification.error({
        message: 'error',
        description: `${res?.message}`,
      });
      setData({ ...data, idNumber: '', idApproved: false });
      return false;
    }
  };
  const handlePreview = (e, type) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setImageFile(imageData);
      setData({ ...data, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = async () => {
        upload(imageData, type);
        return;
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };
  const handlePreviewId = (e, type) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setImageFile(imageData);
      setData({ ...data, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = async () => {
        // const val = await validateId();
        //  if (val) {
        upload(imageData, type);
        //  }
        return;
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const previewImg = (e) => {
    setViewData({ url: e });
    setShowViewDocModal(true);
  };

  const upload = async (file, type) => {
    showLoader();
    let newForm = new FormData();
    newForm.append('file', file);
    newForm.append('fileName', 'UTILITY');
    const res = await httpPost(
      `/upload/others/?fileName=${
        type === 'id' ? 'NATIONAL_ID' : type
      }&userId=${userId}`,
      newForm,
      customBaseUrl.fileResourseUrl
    );
    if (res?.status) {
      hideLoader();
      notification.success({
        message: 'successful',
        description: `${res?.message}`,
      });
      if (type === 'id') {
        setData({ ...data, idCard: true, idCardUrl: res?.data });
      } else if (type === 'PASSPORT') {
        setData({ ...data, passport: true, passportUrl: res?.data });
      } else if (type === 'UTILITY_OFFICE' || type === 'UTILITY_BILL') {
        setData({ ...data, utilityBill: true, utilityBillUrl: res?.data });
      } else if (type === 'UTILITY_HOUSE' || type === 'UTILITY_BILL') {
        setData({ ...data, utilityBill: true, utilityBillUrl: res?.data });
      }
    } else {
      hideLoader();
      notification.error({
        message: 'error',
        description: `${res?.message}`,
      });
      console.log(res);
      /* if (type === 'id') {
        setData({ ...data, idCardUrl: 'upload failed' });
      } else if (type === 'PASSPORT') {
        setData({ ...data, passportUrl: 'upload failed' });
      } else if (type === 'UTILITY_OFFICE') {
        setData({ ...data, utilityOfficeUrl: 'upload failed' });
      } else if (type === 'UTILITY_HOUSE') {
        setData({ ...data, utilityBillUrl: 'upload failed' });
      } */
    }
  };
  const verifyBvn = async (id, bid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/redirect/director/${id}/${bid}`
    );
    if (res?.includes('https')) {
      hideLoader();
      const popup = window.open(
        res,
        'Director BVN',
        `width=${500},
        height=${400},
        left=${200},
        top=${200}`
      );
    } else {
      swal(res?.message ?? '');
      hideLoader();
    }
  };
  useEffect(() => {
    setFiles({});
    if (sData?.businessRequirementId) {
      setDirectorId(sData?.id);
      setIsNew(false);
      setDirStatus({
        status: sData?.status,
        reason: sData?.reason,
      });
      setData(directors[sPage]);
      const id = documents?.filter(
        (e) =>
          e?.director?.id === directors[sPage]?.id &&
          (e?.docType === 'VOTERS_CARD' ||
            e?.docType === 'NIN' ||
            e?.docType === 'NATIONAL_ID' ||
            e?.docType === 'DRIVER_LICENCE' ||
            e?.docType === 'INTERNATIONAL_PASSPORT')
      )[0];
      const photo = documents?.filter(
        (e) =>
          e?.director?.id === directors[sPage]?.id && e?.docType === 'PHOTO'
      )[0];
      const uHouse = documents?.filter(
        (e) =>
          e?.director?.id === directors[sPage]?.id &&
          (e?.docType === 'UTILITY-HOUSE' || e?.docType === 'UTILITY-BILL')
      )[0];
      const uOffice = documents?.filter(
        (e) =>
          e?.director?.id === directors[sPage]?.id &&
          (e?.docType === 'UTILITY-OFFICE' || e?.docType === 'UTILITY-BILL')
      )[0];
      console.log('phoootooo', photo);
      setData({
        ...data,
        ...directors[sPage],
        idCardUrl: id?.docUrl,
        passportUrl: photo?.docUrl,
        utilityBillUrl: uHouse?.docUrl,
        // utilityOfficeUrl: uOffice?.docUrl,
        idCardType: id?.docType,
      });
      setFiles({ ...files, id, uHouse, uOffice, photo });
      console.log('direct22', id, documents);
    } else {
      setData({ ...initial });
      setIsNew(true);
      setDirectorId(null);
      setDirStatus({
        status: '',
        reason: '',
      });
      console.log('direct1', directors[sPage]);
    }
    console.log('sDatas', sData);
    console.log('sPage', sPage);
  }, [sData]);
  return (
    <>
      <div>
      {sPage > 0 ? <div>
          <label style={{ fontSize: 10, color: '#0000fd'}}>
            Director 2 documents is optional
          </label>
        </div>
        : ''
      }
        <div style={{ flexDirection: 'row', textAlign: 'right' }}>
          {/* <button
            type="button"
            className="btn text-right"
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyItems: 'center',
              background: sPage === 0 ? '#FF6700' : '#fff',
            }}
            onClick={() => (sPage > 0 ? setSPage(sPage - 1) : setSPage(0))}
          >
            Director 1
          </button>
          <button
            type="button"
            className="btn text-right"
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyItems: 'center',
              background: sPage === 1 ? '#FF6700' : '#fff',
            }}
            onClick={() => (sPage < 1 ? setSPage(sPage + 1) : setSPage(1))}
          >
            Director 2
          </button> */}
          <div>
            <label>
              <p
                style={{
                  color:
                    dirStatus?.status === 'APPROVED'
                      ? 'green'
                      : dirStatus?.status === 'REJECTED'
                      ? 'red'
                      : 'yellow',
                }}
              >
                {dirStatus?.status}
              </p>
              {dirStatus?.status === 'REJECTED' ? dirStatus?.reason : ''}
            </label>
          </div>
        </div>
        <form onSubmit={handleSave}>
          <div className="col-md-6">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              required
              value={data?.firstName}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              onChange={(e) => setData({ ...data, firstName: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Other Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              value={data?.otherName}
              onChange={(e) => setData({ ...data, otherName: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              value={data?.lastName}
              onChange={(e) => setData({ ...data, lastName: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              value={data?.email}
              required
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              //  disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              maxLength={13}
              onChange={(e)=>{                      
                const onlyDigits = e.target.value.replace(/\D/g, "");
                setData({ ...data, phone: onlyDigits });
              }}
              value={data?.phone}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          {/* <div className="col-md-6">
            <label className="form-label">Ownership Cut</label>
            <input
              type="text"
              disabled={!isNew}
              className="form-control"
              required
              value={data?.ownershipCut}
              onChange={(e) =>
                setData({ ...data, ownershipCut: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            </div> */}
          <div className="col-md-6">
            <label className="form-label">BVN</label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                //  disabled={!isNew}
                // pattern="[1-9]{1}[0-9]{9}"
                className="form-control"
                disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
                required
                value={data?.bvn}
                length="11"
                maxLength={11}
                onChange={(e)=>{                      
                  const onlyDigits = e.target.value.replace(/\D/g, "");
                  setData({ ...data, bvn: onlyDigits });
                }}
              />
              <button
                type="button"
                // disabled={!data?.canProcessBvn}
                onClick={() => verifyBvn(data?.businessRequirementId, data?.id)}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '0px',
                  height: '100%',
                  border: 'none',
                  display: 'none',
                }}
              >
                Verify
              </button>
              <button
                type="button"
                // disabled={!data?.canProcessBvn}
                onClick={() => verifyBvn(data?.businessRequirementId, data?.id)}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '0px',
                  height: '100%',
                  border: 'none',
                  display: 'none',
                }}
              >
                Verify Manually
              </button>
            </div>
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              value={data?.dob}
              onChange={(e) =>
                setData({
                  ...data,
                  dob: moment(e.target.value).format('YYYY-MM-DD'),
                })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Nationality</label>
            <input
              type="text"
              //  disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              value={data?.nationality}
              onChange={(e) =>
                setData({ ...data, nationality: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">State</label>
            <input
              type="text"
              //  disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              value={data?.state}
              onChange={(e) => setData({ ...data, state: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Local Government</label>
            <input
              type="text"
              // disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              value={data?.lga}
              onChange={(e) => setData({ ...data, lga: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Full Address</label>
            <input
              type="text"
              // disabled={!isNew}
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="form-control"
              required
              value={data?.address}
              onChange={(e) => setData({ ...data, address: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          {/* <div className="col-md-6">
            <label className="form-label">Select Id Type</label>
            <select
              className="form-select border"
              // required
              value={data?.idCardType}
              onChange={(e) => setData({ ...data, idCardType: e.target.value })}
            >
              <option>Select ID type</option>
              <option value="NIN">NATIONAL ID</option>
              <option value="DRIVER_LICENCE">DRIVER LICENCE</option>
              <option value="INTERNATIONAL_PASSPORT">
                INTERNATIONAL PASSPORT
              </option>
              <option value="VOTERS_CARD">VOTERS CARD</option>
            </select>
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div> */}
          {/* {data?.idCardType && ( */}
          <div className="col-md-6">
            <label className="form-label">NIN</label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                //  disabled={!isNew}
                disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
                className="form-control"
                // required={isNew}
                value={data?.idNumber}
                onChange={(e) => setData({ ...data, idNumber: e.target.value })}
              />
              {/* <button
                type="button"
                disabled={!data?.idNumber}
                onClick={validateId}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '0px',
                  height: '100%',
                  border: 'none',
                }}
              >
                validate
              </button> */}
            </div>
          </div>
          {/* )} */}
          {/* <div className="col-md-6">
            <label className="form-label">Upload Id</label>
            <input
              hidden
              id="uploadId"
              //  disabled={data?.idCardType && data?.idNumber ? false : true}
              type="file"
              //  required={isNew}
              className="form-file"
              onChange={(e) => {
                handlePreviewId(e, 'id');
                e.target.value = null;
              }}
            />
            <label
              htmlFor="uploadId"
              // className="btn col-md-12"
              className={`col-md-12
                ${files?.id?.status === 'APPROVED' ||
                  files?.id?.status === 'UNDER_REVIEW' ?
                    'disabled btn': 'btn'
                }
              `}
              //  disabled={files?.id?.status !== 'APPROVED' ? false : true}
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload NIN
            </label>
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label text-right">
              <div
                style={{
                  display: data?.idCardUrl ? 'flex' : 'none',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 'auto',
                  padding: '10px',
                }}
              >
                <img
                  className="mt-2"
                  src={data?.idCardUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.idCardUrl)}
                />
                <div
                  className={
                    files?.id?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto',
                    padding: '10px',
                  }}
                >
                  <img
                    src={
                      files?.id?.status === 'APPROVED'
                        ? check
                        : files?.id?.status === 'REJECTED'
                        ? rejected
                        : pending
                    }
                    alt=""
                    className="me-2"
                  />
                  <span>{files?.id?.status}</span>
                  <p>
                    {files?.id?.status === 'REJECTED' ? files?.id?.reason : ''}
                  </p>
                </div>
              </div>
            </label>
          </div> */}
          {/* <div className="col-md-6">
            <label className="form-label">ID Number</label>
            <input
              type="text"
              disabled={!isNew}
              className="form-control"
              // required
              onChange={(e) => setData({ ...data, idNumber: e.target.value })}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div> */}
          {/* <div className="col-md-6">
            <label className="form-label">Passport Photograph</label>
            <label
              htmlFor="uploadPass"
              className={`col-md-12
                ${files?.photo?.status === 'APPROVED' ||
                  files?.photo?.status === 'UNDER_REVIEW' ?
                    'disabled btn': 'btn'
                }
              `}
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to upload passport photograph
            </label>
            <input
              hidden
              id="uploadPass"
              type="file"
              //  required={isNew}
              className="form-file"
              // onChange={(e) => setData({ ...data, passport: e.target.value })}
              onChange={(e) => {
                handlePreview(e, 'PASSPORT');
                e.target.value = null;
              }}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label" style={{ fontSize: 10 }}>
              <div
                style={{
                  display: data?.passportUrl ? 'flex' : 'none',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 'auto',
                  padding: '10px',
                }}
              >
                <img
                  className="mt-2"
                  src={data?.passportUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.passportUrl)}
                />
                <div
                  className={
                    files?.photo?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto',
                    padding: '10px',
                  }}
                >
                  <img
                    src={
                      files?.photo?.status === 'APPROVED'
                        ? check
                        : files?.photo?.status === 'REJECTED'
                        ? rejected
                        : pending
                    }
                    alt=""
                    className="me-2"
                  />
                  <span style={{ fontSize: 10 }}>{files?.photo?.status}</span>
                  <p>
                    {files?.photo?.status === 'REJECTED'
                      ? files?.photo?.reason
                      : ''}
                  </p>
                </div>
              </div>
            </label>
          </div> */}
          {/* <div className="col-md-6">
            <label className="form-label">Utility House</label>
            <label
              htmlFor="uH"
              // className="btn col-md-12"
              className={`col-md-12
                ${files?.uHouse?.status === 'APPROVED' ||
                  files?.uHouse?.status === 'UNDER_REVIEW' ?
                    'disabled btn': 'btn'
                }
              `}
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload Utility Bill
            </label>
            <input
              hidden
              id="uH"
              type="file"
              //  required={isNew}
              className="form-file"
              onChange={(e) => {
                handlePreview(e, 'UTILITY_BILL');
                e.target.value = null;
              }}
              onChange={(e) =>
                // setData({ ...data, utilityBill: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label" style={{ fontSize: 10 }}>
              <div
                style={{
                  display:
                    data?.utilityBillUrl || files?.uHouse?.status
                      ? 'flex'
                      : 'none',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 'auto',
                  padding: '10px',
                }}
              >
                <img
                  className="mt-2"
                  src={data?.utilityBillUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.utilityBillUrl)}
                />
                <div
                  className={
                    files?.uHouse?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: 'auto',
                    padding: '10px',
                  }}
                >
                  <img
                    src={
                      files?.uHouse?.status === 'APPROVED'
                        ? check
                        : files?.uHouse?.status === 'REJECTED'
                        ? rejected
                        : pending
                    }
                    alt=""
                    className="me-2"
                  />
                  <span style={{ fontSize: 10 }}>{files?.uHouse?.status}</span>
                  <p>
                    {files?.uHouse?.status === 'REJECTED'
                      ? files?.uHouse?.reason
                      : ''}
                  </p>
                </div>
              </div>
            </label>
          </div> */}
          {/* <div className="col-md-6">
            {/* <label className="form-label">Utility Office</label>
            <label
              htmlFor="uO"
              className="btn col-md-12"
              style={{ borderWidth: 1, color: '#fff', background: 'gray' }}
            >
              Click here to Upload Utility Office
            </label>
            <input
              hidden
              type="file"
              title=" "
              //  // required={isNew}
              className="form-file col-md-12"
              onChange={(e) => {
                handlePreview(e, 'UTILITY_OFFICE');
                e.target.value = null;
              }}
              id="uO"
              /* onChange={(e) =>
                // setData({ ...data, utilityOffice: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
            <label className="form-label" style={{ fontSize: 8 }}>
              {isNew && data?.utilityOfficeUrl ? (
                <img
                  className="mt-2"
                  src={data?.utilityOfficeUrl}
                  width={30}
                  height={30}
                  onClick={() => previewImg(data?.utilityOfficeUrl)}
                />
              ) : (
                <div
                  className={
                    files?.uOffice?.status !== 'APPROVED'
                      ? 'status-pending'
                      : 'status-true'
                  }
                >
                  <img
                    src={
                      files?.uOffice?.status === 'APPROVED'
                        ? check
                        : files?.uOffice?.status === 'REJECTED'
                        ? rejected
                        : pending
                    }
                    alt=""
                    className="me-2"
                  />
                  <span style={{ fontSize: 10 }}>{files?.uOffice?.status}</span>
                  <p>
                    {files?.uOffice?.status === 'REJECTED'
                      ? files?.uOffice?.reason
                      : ''}
                  </p>
                </div>
              )}
            </label>
          </div> */}
          {/* removing next of kin from requirement  */}
          {/* <div className="col-md-6">
            <label className="form-label">Next of Kin First Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinFirstName}
              onChange={(e) =>
                setData({ ...data, nextOfKinFirstName: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Last Name</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinLastName}
              onChange={(e) =>
                setData({ ...data, nextOfKinLastName: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Email Address</label>
            <input
              type="email"
              className="form-control"
              required
              value={data?.nextOfKinEmail}
              onChange={(e) =>
                setData({ ...data, nextOfKinEmail: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Phone Number</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinPhone}
              maxLength={13}
              onChange={(e)=>{                      
                const onlyDigits = e.target.value.replace(/\D/g, "");
                setData({ ...data, nextOfKinPhone: onlyDigits });
              }}
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div>
          <div className="col-md-6">
            <label className="form-label">Next of Kin Address</label>
            <input
              type="text"
              //  disabled={!isNew}
              className="form-control"
              required
              value={data?.nextOfKinAddress}
              onChange={(e) =>
                setData({ ...data, nextOfKinAddress: e.target.value })
              }
            />
            <div
              style={{
                color: 'red',
                fontSize: 9,
              }}
            ></div>
          </div> */}
          <div style={{ textAlign: 'center' }}>
            {/* {isNew ? (
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Update
              </button>
            )} */}
            <button
              disabled={dirStatus?.status === 'APPROVED' || dirStatus?.status === 'UNDER_REVIEW'}
              className="btn btn-primary" type="submit">
              Save and Continue
            </button>
          </div>
        </form>
        {viewDocModal ? (
          <ViewDocModal
            showModal={viewDocModal}
            hideModal={setShowViewDocModal}
            data={viewData}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
