import React, { useEffect, useState, useContext } from 'react';
import { ExportJsonCsv } from 'react-export-json-csv';
import { FiDownload } from 'react-icons/fi';
import { Col, Row } from '../../../shared/containers/containers';
import TableHeaders from '../../../shared/tableHeaders/headers';
import styles from './styles.module.css';
import { httpGet, customBaseUrl } from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import CommissionStats, {
  RevenueStats,
  CommissionCategory,
} from './commissions-components/commisions';

const Stats = ({
  allDis,
  res,
  rev,
  rej,
  settled,
  tranCount,
  setSearchText,
  LogClick,
  refresh,
  header,
  exportData,
  setShowWithdraw,
  type,
  setType,
  ticketId,
  setTicketId,
  status,
  setStatus,
  handleFilter,
}) => {
  const { userDetails } = useContext(ProfileContext);

  const [data, setData] = useState({});

  const getDisputes = async () => {
    // const url = `https://services.staging.wayabank.ng/complaint-service/disputes`;
    // const res = await httpGet(url);
    const res1 = await httpGet(
      `${customBaseUrl.wayapayDispute}/users/${userDetails.userId}/disputes?page=0`
    );

    if (res1?.status) {
      const allDisputes = res1?.data?.userDisputes;

      const ALL_DISPUTE_COUNT = allDisputes.length;
      const RESOLVED_DISPUTES = allDisputes.filter(
        (element) => element.status === 'RESOLVED_DISPUTE'
      ).length;
      const UNDER_REVIEW_DISPUTES = allDisputes.filter(
        (element) => element.status === 'WAYAPAY_REVIEWING'
      ).length;
      const DISPUTES_REJECTED = allDisputes.filter(
        (element) => element.status === 'DISPUTE_REGECTED'
      ).length;
      const PAYMENT_DISPUTES = allDisputes.filter(
        (element) => element.category === 'Payment Dispute'
      ).length;
      const SOCIAL_DISPUTES = allDisputes.filter(
        (element) => element.category === 'Social Dispute'
      ).length;
      const AUTH_DISPUTES = allDisputes.filter(
        (element) => element.category === 'Auth & Notification'
      ).length;
      const OTHER_DISPUTES = allDisputes.filter(
        (element) => element.category === 'Other Disputes'
      ).length;
      setData({
        ALL_DISPUTE_COUNT,
        RESOLVED_DISPUTES,
        DISPUTES_REJECTED,
        UNDER_REVIEW_DISPUTES,
        PAYMENT_DISPUTES,
        SOCIAL_DISPUTES,
        AUTH_DISPUTES,
        OTHER_DISPUTES,
      });
    }
  };

  useEffect(() => {
    // getDisputes();
  }, [userDetails.userId, refresh]);
  console.log(data);
  return (
    <>
      {/* <Col style={{ marginTop: 20 }} className={styles.TableHeaders}>
        <TableHeaders
          LogClick={LogClick}
          exportData={exportData}
          exportHeader={header}
          type={type}
          setType={setType}
          ticketId={ticketId}
          setTicketId={setTicketId}
          status={status}
          setStatus={setStatus}
          handleFilter={handleFilter}
        />
      </Col> */}
      <div className="container">
        <button
          type="button"
          onClick={() => setShowWithdraw(true)}
          className="btn border shadow float-right"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 0,
            background: '#FFFFFF',
            boxShadow: 1,
            borderRadius: 20,
            color: '#ff6700',
          }}
        >
          Withdraw Commission
        </button>
      </div>
      <Row justify="space-between" className={styles.transactionAmount}>
        <RevenueStats
          color="#000000"
          name="TOTAL ONBOARDED AGENTS "
          count={allDis ?? 0}
        />
        <RevenueStats
          color="#000000"
          name="TOTAL TRANSACTION COUNT"
          count={tranCount ?? 0}
          url="/referrals-transaction"
        />
        <RevenueStats
          color="#000000"
          name="TOTAL COMMISSION GENERATED "
          count={res ?? 0}
        />
        <CommissionStats
          color="#ffffff"
          name="TOTAL PENDING COMMISSION "
          count={rev ?? 0}
        />
        <CommissionStats
          color="#ffffff"
          name="TOTAL SETTLED COMMISSION"
          count={settled ?? 0}
        />
      </Row>
      <Row justify="space-around" className={styles.disputeTypes}>
        <Col>
          <div>Filter by Business Type</div>
          <div className="inputbox-with-one-input">
            <select className="mt-3 border form-select">
              <option>ALL</option>
            </select>
          </div>
        </Col>
        <Col>
          <div>
            <input
              style={{
                marginRight: 10,
                borderRadius: 20,
                width: 250,
                padding: 10,
              }}
              onChange={(e) => setSearchText(e.target.value)}
              className="input r-7 mr-1"
              placeholder="Search by email or phone"
            />
          </div>
        </Col>
        <Col>
          {/* <div>Filter by Date</div>
          <Row>
            <div className="input-group mb-3">
              <span className="input-group-text">From</span>
              <input
                type="date"
                className="form-control"
                placeholder="Username"
                aria-label="Username"
              />
              <span className="input-group-text">To</span>
              <input
                type="date"
                className="form-control"
                placeholder="Server"
                aria-label="Server"
              />
            </div>
            </Row> */}
        </Col>
        {/*<CommissionCategory color="#000000" name="TOTAL AGENTS" count={0} />
        <CommissionCategory color="#000000" name="TOTAL MERCHANTS" count={0} />
        <CommissionCategory color="000000" name="TOTAL AGGREGATORS" count={0} />
    */}
      </Row>
      <Row justify="space-around" className={styles.disputeTypes}>
        <div />
        <div />
        <ExportJsonCsv
          style={{ border: 'none', background: 'none' }}
          headers={header}
          items={exportData}
        >
          <div className="btn_Export">
            Export
            <span style={{ marginLeft: 5 }}>
              <FiDownload color="#000000" />
            </span>
          </div>
        </ExportJsonCsv>
      </Row>
    </>
  );
};

export default Stats;
