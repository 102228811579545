import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Cards from "../../components/Cards/Cards";
import { VscAdd } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import Layout from '../../../components/shared/Layout';

import {
  allTerminals,
  searchAllTerminalsByParam,
  searchRequestedTerminalsByParam,
  terminalStats,
  terminalUrl,
} from "../../plugins/urls";
import useSimpleTable from "../../components/Hooks/useSimpleTable";
import { terminalFilters, terminalTableCols } from "../../utilities/dummyData";
import { apiCall } from "../../utilities/apis";
import CircularLoader from "../../components/SubmitLoader/CircularLoader";
import { AiFillEye } from "react-icons/ai";
import useUser from "../../components/Hooks/useUser";
import EllipsisLoader from "../../components/SubmitLoader/ElipseLoader";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import StepButton from "../../../components/shared/Modals/Post/DonationModal/StepButton";
import AddPin from "./addPin";
import UpdatePin from "./updateTerminalPin";
import ComplaintModal from "./complain";
import { hideLoader, showLoader } from "../../../utils/loader";
import Swal from "sweetalert2";
import ResetPin from "./ResetPin";

const Terminal = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [addPin, setAddPin] = useState(false);
  const [updatePin, setUpdatePin] = useState(false);
  const [resetPin, setResetPin] = useState(false);
  const [terminalCom, setTerminaCom] = useState(false);
  const [selectedTerminal, setSelectedterminal] = useState([]);

  const [state, setState] = useState({
    activeTerminals: "",
    damagedTerminals: "",
    suspendedTerminals: "",
    totalTerminals: "",
  });

  const {
    activeTerminals,
    damagedTerminals,
    suspendedTerminals,
    totalTerminals,
  } = state;

  const getTerminalStats = () => {
    apiCall({
      method: "get",
      setLoading: setLoadingStats,
      url: terminalStats,
      noError: true,
      success: (data) => {
        const {
          activeTerminals,
          damagedTerminals,
          suspendedTerminals,
          totalTerminals,
        } = data.respBody;
        setState((state) => ({
          activeTerminals,
          suspendedTerminals,
          totalTerminals,
          damagedTerminals,
        }));
      },
    });
  };

  const handleResetPin = () => {
    showLoader();
    apiCall({
      method: "get",
      setLoading: setLoadingStats,
      url: `${terminalUrl}/terminals/generate-terminal-otp/${selectedTerminal?.id}`,
      noError: true,
      success: (data) => {
        hideLoader();
        setResetPin(true);
        Swal.fire('Done', data?.respBody);
      },
    });
  };

  // const actionTerminal = (terminal) => navigate(`/terminals/${terminal.id}`);
  const actionTerminal = (terminal) => { 
    setSelectedterminal(terminal);
    setShowOption(true);
  };


  /* custom hooks */
  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: allTerminals,
    includeMeta: true,
    filters: terminalFilters,
    filterUrl: searchAllTerminalsByParam,
    showSearchIcon: true,
    csvName: "WAYA_POS_TERMINALS_",
    dateFilterMenuName: "date",
    cols: terminalTableCols(
      actionTerminal,
      <AiFillEye size={20} color="#FF6700" />
    ),
    filterCallMethod: 3,
    dataAddOn: {
      paramList: [
        {
          userid: user?.id,
        },
      ],
    },
    // deps: [walletNo],
    startCb: () => {
      getTerminalStats();
    },
  });

  return (
    <Layout title="Terminals">
      <Modal
        isOpen={showOption}
        id="blockedUser-modal"
        toggle={() => setShowOption(false)}
        centered
      >
        <ModalHeader className="" toggle={() => setShowOption(false)}>
          <div className="custome-header">
            <div>Terminal Actions {selectedTerminal?.actualTerminalName}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            {!selectedTerminal?.transPin ? <StepButton  title="Add Pin" onClick={()=> setAddPin(true)}/> :
            <StepButton title="Update Pin" onClick={()=> setUpdatePin(true)}/>}
            <StepButton title="Reset Pin" onClick={() => handleResetPin()}/>
            <StepButton title="Initiate Complaint" onClick={() => setTerminaCom(true)}/>
            <StepButton title="Transaction History" onClick={() => navigate(`/terminal-transactions/${selectedTerminal?.terminalId}`)}/>
            <StepButton title="Terminal Logs" onClick={() => navigate(`/pos-activity-log/${selectedTerminal?.id}`)}/>
          </div>
        </ModalBody>
        {addPin ?
          <AddPin
            hideModal={setAddPin}
            showModal={addPin}
            selectedTerminal={selectedTerminal}
          /> : ''
      }
      {updatePin ?
          <UpdatePin
            hideModal={setUpdatePin}
            showModal={updatePin}
            selectedTerminal={selectedTerminal}
          /> : ''
      }
      {resetPin ?
          <ResetPin
            hideModal={setResetPin}
            showModal={resetPin}
            selectedTerminal={selectedTerminal}
          /> : ''
      }
      {terminalCom ?
          <ComplaintModal
            hideModal={setTerminaCom}
            showModal={terminalCom}
            selectedTerminal={selectedTerminal}
          /> : ''
      }
      </Modal>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>

          <div className="d-flex justify-content-start align-items-center mt-md-1 mt-xl-0">
            {tableData?.content?.length > 0 && exportButton}
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="request-button"
                onClick={() => {
                  navigate("/add-terminal");
                }}
              >
                <VscAdd color={"#fff"} className="mr-5" />
                Request new terminal
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="request-button"
                onClick={() => {
                  navigate("/terminal-requests");
                }}
              >
                View terminal requests
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container fluid>
        {loadingStats ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Issued Terminal"
                value={totalTerminals}
                color="text-darker"
                textColor="text-darker"
              />
            </Col>
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Active Terminals"
                value={activeTerminals}
                color="text-sharp-green"
                textColor="text-sharp-green"
              />
            </Col>
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Damaged Terminals"
                value={damagedTerminals}
                color="text-semi-dark"
                textColor="text-semi-dark"
              />
            </Col>
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Suspended Terminals"
                value={suspendedTerminals}
                color="text-red"
              />
            </Col>
          </Row>
        )}

        <div>{loading ? <CircularLoader /> : simpleTable}</div>
      </Container>
    </Layout>
  );
};

export default Terminal;
