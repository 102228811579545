import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { Input, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../shared/Button';

import Swal from 'sweetalert2';

const AccountListModal = (props) => {
  const {
    showModal,
    hideModal,
    setPostData,
    postData,
    verificationMethod,
    setVerificationMethod,
    handleContinue,
    loading,
    setIdType,
    methods,
  } = props;
  const [disable, setDisable] = useState(true);

  useEffect(() => {

  }, []);
  return (
    <Modal isOpen={showModal} id="" toggle={() => hideModal(false)} centered>
      <ModalHeader className="text-left">Select OTP options</ModalHeader>
      <ModalBody>
        <div className="my-3">
        {/* <select
            className="border border-2 border-radius-2"
            onChange={(e) => {
            setIdType(e.target.value);
            }}
        >
            <option value="" disabled selected>
            Select methods
            </option>
            {methods?.map(e =>
            <option value={e?.methods}>{e?.hint}</option>
            )}
        </select> */}
        {methods?.map(e =>
          <div
            key={e?.method}
            style={{ cursor: 'pointer', backgroundColor: e?.method === verificationMethod?.method ? '#ff6700': '' }} 
            className={`border w-full h-30 my-4 px-2 py-2`}
            onClick={() => {
                setVerificationMethod(e);
                console.log({met: e})
            }}
        >
            {e?.hint}
          </div>
        )}
        {verificationMethod?.method ?
            <div className="my-3">
                <Input
                placeholder={`Enter phone number`}
                value={verificationMethod?.phone_number}
                onChange={(e) =>
                    setVerificationMethod({
                    ...verificationMethod,
                    phone_number: e.target.value,
                    })
                }
                />
        </div>
        : ''}
        </div>
          <div className="w-100 my-5 text-center">
            <Button
              style={{}}
              type="button"
              loading={loading}
              onClick={() => {
                if (verificationMethod?.phone_number) {
                    handleContinue()
                } else {
                    Swal.fire('Oops!', 'Input phone number')
                }
              }}
              content="Continue"
              className="bg-primary w-50 p-2 rounded-1 text-white border-0"
            />
          </div>
      </ModalBody>
    </Modal>
  );
};

export default AccountListModal;
