import React, { useState } from "react";
import useUser from "../../components/Hooks/useUser";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import StepButton from "../../../components/shared/Modals/Post/DonationModal/StepButton";
import OtpInput from "react-otp-input";
import { addTerminalPin, terminalStats, updateTerminalPin } from "../../plugins/urls";
import Swal from "sweetalert2";
import { message } from "antd";
import { apiCall } from "../../utilities/apis";
import axios from "axios";
import { Slide, toast } from "react-toastify";
import { hideLoader, showLoader } from "../../../utils/loader";

const UpdatePin = ({selectedTerminal, hideModal, showModal}) => {
  const { user } = useUser();
  const [showOption, setShowOption] = useState(false);
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');

  const handlePin = async() => {
    showLoader();
    try {
      await axios
      .get(
        `${updateTerminalPin}/${selectedTerminal?.id}/${oldPin}/${newPin}`
      )
      .then((res) => {
        if (res?.status == 200) {
          // setOpen(true);
          // setTerminalRequestResp(true);
          Swal.fire(res?.data?.respDesc, "Pin updated successfully")
          toast.success("Pin updated successfully", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          Swal.fire('Oops!', res?.data?.respBody ?? res?.data?.respDesc);
          toast.error(res?.data?.respBody ?? res?.data?.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .finally(() => {
        hideModal(false);
        hideLoader();
      });
    } catch (error) {
      Swal.fire('Oops!', error?.response?.data?.respBody);
      toast.error(error?.response?.data?.respBody, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      hideLoader();
    }
  };
  return (
    <Modal
    isOpen={showModal}
    id="blockedUser-modal"
    toggle={() => hideModal(false)}
    centered
    >
    <ModalHeader className="" toggle={() => hideModal(false)}>
        <div className="custome-header">
        <div>Add pin to {selectedTerminal?.actualTerminalName}</div>
        </div>
    </ModalHeader>
    <ModalBody>
        <div style={{ margin: 20, alignContent: 'center'}}>
          <label className="fs-14 fw-bold my-3">Old pin</label>
      <OtpInput
        value={oldPin}
        onChange={(e) => setOldPin(e)}
        numInputs={4}
        separator=""
        containerStyle={{ justifyContent: 'space-between' }}
        inputStyle="otp-inputs"
        isInputSecure
        isInputNum
      />
       <label className="fs-14 fw-bold my-3">New pin</label>
      <OtpInput
        value={newPin}
        onChange={(e) => setNewPin(e)}
        numInputs={4}
        separator=""
        containerStyle={{ justifyContent: 'space-between' }}
        inputStyle="otp-inputs"
        isInputSecure
        isInputNum
      />
        <StepButton title="Update Pin" onClick={handlePin}/>
        </div>
    </ModalBody>
    </Modal>
  );
};

export default UpdatePin;
