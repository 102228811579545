import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { toPng } from 'html-to-image';
import { Modal, ModalBody, Button, ModalHeader } from 'reactstrap';
import './update.scss';
import { ProfileContext } from '../../../store/context/ProfileContext';
import {
  customBaseUrl,
  httpPost,
  httpPostFormData,
} from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const ManualBvn = (props) => {
  const { showModal, hideModal, hideAlert } = props;
  const [bvn, setBvn] = useState('');
  const [bvnData, setBvnData] = useState([]);
  const textRef = useRef();
  const [file, setFile] = useState(null);

  const {
    // profile: { firstName, surname, dateOfBirth, email, phoneNumber },
    userDetails,
    profile: { firstName, surname, email: uEmail },
    profile,
  } = useContext(ProfileContext);

  const handleSubmit = async () => {
    showLoader();
    const dataUrl = await toPng(textRef.current);
    const blob = await fetch(dataUrl).then((res) => res.blob());
    const file = new File([blob], 'text-image.png', { type: 'image/png' });
    setFile(file);
    const newForm = new FormData();
    newForm.append('fileName', 'BVN');
    newForm.append('file', file);
    newForm.append('identificationNumber', bvn);
    newForm.append('id', bvnData?.id);
    newForm.append('userId', profile.userId);
    const res = await httpPost(
      `/api/v1/kyc/upload/documents/${profile?.userId}`,
      newForm,
      customBaseUrl.kycUrl
    );
    if (res?.status) {
      swal('Done', res?.message, 'success').then(() => {
        hideLoader();
        approvalNoti();
        Swal.fire('Done!', res?.message, 'Success');
        hideAlert(false);
        hideModal();
      });
    } else {
      hideLoader();
      Swal.fire('Oops!', res?.message, 'error');
    }
  };

  const getKYCDoc = async () => {
    console.log({ profile });
    showLoader();
    const res = await httpPost(
      `/api/v1/kyc/requirementData/${profile.userId}`,
      {},
      customBaseUrl.kycUrl
    );
    if (res?.status) {
      // console.log('bvn', res);
      hideLoader();
      if (res?.data) {
        const bvn1 = res?.data?.tier2?.filter(
          (e) => e?.requirementType === 'BVN'
        )[0];
        console.log('bvn', bvn1);
        setBvnData(bvn1);
      } else {
        console.log(res);
      }
    } else {
      hideLoader();
    }
  };

  const approvalNoti = async () => {
    showLoader();
    const data = {
      attachment: 'string',
      data: {
        message: `BVN document has been submitted for approval`,
        names: [
          {
            email: process.env.KYC_EMAIL ?? 'staging.wayaadmin@wayapaychat.com',
            fullName: 'Waya Admin',
          },
        ],
      },
      eventCategory: 'WELCOME',
      eventType: 'EMAIL',
      htmlCode: `<p>Email: ${uEmail}</p>
      <p>Name: ${firstName} ${surname}</p>
      <p>BVN: ${bvn}`,
      initiator: firstName,
      productType: 'WAYABANK',
      subject: `BVN Document submitted`,
    };
    const res = await httpPost(`/email-notification`,
      data,
      customBaseUrl.notificationUrl
    );
    if (res.status === true) {
      hideLoader();
    } else {
      hideLoader();
    }
  };
  useEffect(() => {
    getKYCDoc();
  }, []);
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader>
        <h5 className="modal-header-data"> BVN Submission </h5>
      </ModalHeader>
      <ModalBody>
        <div ref={textRef}>
          <label>BVN</label>
          <div className="inputbox-with-one-input">
            <input
              placeholder="BVN"
              length="11"
              maxLength={11}
              type="text"
              value={bvn}
              onChange={(e)=>{                      
                const onlyDigits = e.target.value.replace(/\D/g, "");
                setBvn(onlyDigits);
              }}
            />
          </div>
        </div>
        {/* <div style={{ padding: '20px', display: 'none' }}>
          <div >
            <h1>BVN: {bvn}</h1>
          </div>
        </div> */}
        <div className="text-center m-4">
          <Button
            style={{ width: '200px' }}
            color="primary"
            onClick={handleSubmit}
          >
            Proceed
          </Button>
        </div>
        {/* <ModalFooter>
          <Button color="secondary" onClick={() => hideModal(false)}>
            Proceed
          </Button>
        </ModalFooter> */}
      </ModalBody>
    </Modal>
  );
};

export default ManualBvn;
