import React, { useState, useEffect, useContext } from 'react';
import { Pagination } from '@mui/material';
import Table from '../../../shared/Table/DisputeTable';
import Layout from '../../../shared/Layout';
import DisputeWrapper from './style';
import './index.css';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  // httpPostFormData,
} from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import Stats from './stats';
import moment from 'moment';
import WithdrawCommission from './withdraw';
import { TablePagination } from '@material-ui/core';

const ReferralsTransactions = (props) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [status, setStatus] = useState('');
  const [filteredObj, setFilteredObj] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [allDis, setAllDis] = useState([]);
  const [meta, setMeta] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const { userDetails } = useContext(ProfileContext);

  const getCommissionHis = async () => {
    showLoader();
    const pages = page > 0 ? page - 1 : page;
    const res = await httpPost(
      `/api/v1/commission_report/fetchCustomerTransactionCommissions`,
      {
        pageNo: pages,
        pageSize: rowsPerPage
      },
      customBaseUrl.referalUrl
    );
    if (res?.status) {
      setData(res?.data?.trans);
      setMeta(res?.totalItems);
    }
    hideLoader();
    console.log('dataDis', data);
  };

  const handleFilter = (obj) => {
    setFilteredObj(obj);
  };
  const handleChange = (event, value) => {
    setPage(value);
    // eslint-disable-next-line
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getCommissionHis()
    //  setFiltered(data);
  }, [userDetails.userId, page]);

  return (
    <Layout route={props} currentPage="commission">
      <DisputeWrapper>
        <Table
          columns={[
            // { title: 'TRACKING NUMBER', field: 'trackingNumber' },
            // { title: 'DATE', field: 'transactionDate' },
            // { title: 'STATUS', field: 'status' },
            // { title: 'ACTION', field: 'action' },
            { title: 'USER NAME', field: 'customerName' },
            { title: 'USER EMAIL', field: 'customerEmail' },
            // { title: 'CATEGORY', field: 'category' },
            { title: 'USER PHONE', field: 'customerPhone' },
            {
              title: 'REFERRAL NAME',
              field: 'referralName',
            },
            {
              title: 'PAYMENT REFERRENCE',
              field: 'paymentReference',
            },
            {
              title: 'TRANS. REFERENCE',
              field: 'transactionReferenceNumber',
            },
            {
              title: 'COMMISSION TYPE',
              field: 'commissionType',
            },
            {
              title: 'COMMISSION',
              field: 'commission',
            },
            {
              title: 'STATUS',
              field: 'status',
            },
            {
              title: 'DATE CREATED ',
              field: 'dateCreated',
              render: (row) => row?.dateCreated ?? 'N/A',
            },
          ]}
          data={
            searchText
              ? data?.filter(
                  (e) =>
                    e?.email
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase()) ||
                    e?.phoneNumber
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase())
                )
              : data
          }
        />
        <Pagination
          count={Math.ceil(meta / rowsPerPage)}
          page={page}
          onChange={handleChange}
        />
      </DisputeWrapper>
      {showWithdraw ? (
        <WithdrawCommission
          showModal={showWithdraw}
          hideModal={setShowWithdraw}
        />
      ) : null}
    </Layout>
  );
};

export default ReferralsTransactions;
