import React, { useState, useEffect, useContext, useRef } from 'react';
import swal from 'sweetalert';
// import { Input, Button } from 'antd';
// import validator from 'validator';
// import { UploadOutlined } from '@ant-design/icons';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import frontArrow from '../../../assets/image/front_arrow.svg';
import backArrow from '../../../assets/image/back_arrow.svg';
import check from '../../../assets/image/check.svg';
import pending from '../../../assets/image/pending.svg';
import rejected from '../../../assets/image/rejected.svg';
import upload from '../../../assets/image/upload.svg';
import { ProfileContext } from '../../../store/context/ProfileContext';
import UploadModal from './UploadModal';
import { hideLoader, showLoader } from '../../../utils/loader';
import VerifyEmailAndPhone from './VerifyEmailAndPhone';
import UploadShareHolder from './uploadShareHolders';
import { Row } from 'reactstrap';
import UploadDirectors from './uploadDirectors';
import CacTier4 from './uploadCacTier4';
import BusinessReg from './businessReg';
import KYCUploadMode from './uploadModeModal';
import { toPng } from 'html-to-image';

const UploadTier = (props) => {
  const { kycStatus, level } = props;
  const textRef = useRef();
  const {
    userDetails,
    verifyDetails,
    profile: { firstName, surname, email: uEmail, phoneNumber },
  } = useContext(ProfileContext);
  const [showModal, hideModal] = useState(false);
  const [postData, setPostData] = useState({});
  //  const [bvnStat, setBvnStat] = useState();
  const [loading, setLoading] = useState(false);
  const [idType, setIdType] = useState('nationalId');
  const [previewImage, setPreviewImage] = useState(null);
  const [name, setName] = useState('');
  const [utilityBillStat, setUtilityBillStat] = useState([]);
  //  const [trnxlevels, settrnxLevels] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState('');
  const [userAddress, setUserAddress] = useState();
  //  const [tin, setTin] = useState();
  const [uploadDoc, setUploadDoc] = useState();
  const [mermatStatus, setMermatStatus] = useState();
  const [tinStatus, setTinStatus] = useState();
  /* const [level1, setLevel1] = useState([]);
  const [level2, setLevel2] = useState([]);
  const [level3, setLevel3] = useState([]);
  const [level4, setLevel4] = useState([]); */
  //  console.log('kycStatus', kycStatus);
  const [page, setPage] = useState(1);
  const [docs, setDocs] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [bvn, setBvn] = useState([]);
  const [passport, setPassport] = useState([]);
  const [id, setId] = useState([]);
  const [utility, setUtility] = useState([]);
  const [cac, setCac] = useState([]);
  const [tin, setTin] = useState([]);
  const [isCac, setIsCac] = useState(
    verifyDetails?.profileSubscription?.cacAccount ?? false
  );
  const [reference, setReference] = useState([]);
  const [cacTier1, setCacTier1] = useState({});
  const [cacTier2, setCacTier2] = useState({});
  const [cacTier3, setCacTier3] = useState({});
  const [cacTier4, setCacTier4] = useState({});
  const [directors, setDirectors] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [shareHolders, setShareHolders] = useState([]);
  const [viewShareholders, setViewShareholders] = useState(false);
  const [sPage, setSPage] = useState(0);
  const [dirStatus, setDirStatus] = useState({});
  const [dPage, setDPage] = useState(0);
  const [showBusReg, setShowBusReg] = useState(false);
  const [reload, setReload] = useState(false);
  const [showUploadMode, setShowUploadMode] = useState(false);
  const [uploadMode, setUploadMode] = useState('auto');
  const [isSummary, setIsSummary] = useState(false);
  const [overviewStatus, setOverviewStatus] = useState({});
  const [tier4Status, setTier4Status] = useState(false);
  const [teir3Status, setTeir3Status] = useState(false);
  const [nin, setNin] = useState('');
  const [ninId, setNinId] = useState([]);
  // let {userCAC, userInternationalPassport, userPassportPhotograph, userDriversLicense, userNationalId,userVotersCard, usersUtilityBill} = kycStatus;
  const {
    userPassportPhotograph,
    userInternationalPassport,
    userDriversLicense,
    userNationalId,
    userVotersCard,
    usersUtilityBill,
    userCAC,
    TIN,
  } = kycStatus || {};

  const getAnyDoc = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/upload-any-document/find-one-document/${userDetails.userId}`
    );
    console.log('anyDoc', res);
    setUploadDoc(res);
    console.log('uploadDoc', uploadDoc);
    if (res?.fileName === 'MERMAT') {
      setMermatStatus(res.adminStatus);
      console.log('mermat', mermatStatus);
    }
    if (res?.fileName === 'TIN') {
      setTinStatus(res.adminStatus);
      console.log('TinStatus', tinStatus);
    }
  };
  // const getMermat = () => {
  //   if (uploadDoc.length) {
  //     const findMermat = uploadDoc.find((e) => e.fileName === 'MERMAT');
  //     setMermatStatus(findMermat.adminStatus);
  //     console.log('mermatStatus', mermatStatus);
  //   }
  // };
  const handlePage = (type) => {
    if (type === 'previous') {
      setPage(page - 1);
    }
    if (type === 'next') {
      setPage(page + 1);
    }
  };

  /*  const handleTin = async () => {
    if (validator.isEmpty(tin)) {
      swal('Oops!', 'TIN cannot be empty', 'error');
      return;
    }

    if (/\D/.test(tin)) {
      swal('Oops!', 'Only numbers are allowed', 'error');
      return;
    }

    if (tin.length < 9) {
      swal('Oops!', 'TIN cannot be be more than 9 numbers', 'error');
      return;
    }

    const res = await httpPost(
      `/upload-any-document/other-requirement?id=${userDetails.userId}&name=TIN&value=${tin}`,
      tin,
      customBaseUrl.kycUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideLoader();
        hideModal(false);
        setLoading(false);
        setPostData({});
        setPreviewImage(null);
        setTin();
      });
    } else {
      hideLoader();
      swal('Oops!', res.message, 'error');
      setLoading(false);
    }
  }; */
  const callManualUpload = async (item) => {
    showLoader();
    const data = {
      attachment: 'string',
      data: {
        message: `The user with the following information is requesting for  ${item} manual verification on the admin dashboard`,
        names: [
          {
            email: process.env.KYC_EMAIL ?? 'wayaadmin@wayapaychat.com',
            fullName: 'Waya Admin',
          },
        ],
      },
      eventCategory: 'WELCOME',
      eventType: 'EMAIL',
      htmlCode: `<p>Email: ${uEmail}</p><p>Name: ${firstName} ${surname}</p> <p>UserId: ${userDetails.userId}`,
      initiator: uEmail,
      phoneNumber,
      productType: 'WAYABANK',
      subject: `Request for manual ${item} Verification`,
    };
    const res = await httpPost(
      '/email-notification',
      data,
      customBaseUrl.notificationUrl
    );
    if (res.status === true) {
      hideLoader();
      hideModal(false);
    } else {
      swal('Oops!', res?.message ?? 'error', 'error');
      hideLoader();
    }
  };
  const handleUploadDoc = async (type, image) => {
    const d = new Date();
    setLoading(true);
    showLoader();
    const newForm = new FormData();
    newForm.append('file', image);
    newForm.append('userId', userDetails?.userId);
    if (type === 'utility') {
      /*  newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/utility/bill/create/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      } */
      newForm.append('fileName', 'UTILITY');
      newForm.append('identificationNumber', d.getTime());
      newForm.append('id', utility?.id);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status === true) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          getKYCDoc();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('utility');
        }
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'photograph') {
      newForm.append('fileName', 'PHOTO');
      newForm.append('identificationNumber', d.getTime());
      newForm.append('id', passport?.id);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          getKYCDoc();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('photograph');
        }
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'Address') {
      newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/address/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('address');
        }
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'cac') {
      newForm.append('fileName', 'CAC');
      newForm.append('id', cac?.id);
      newForm.append('identificationNumber', postData?.identificationNumber);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          getKYCDoc();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'tin') {
      newForm.append('fileName', 'TIN');
      newForm.append('identificationNumber', postData?.identificationNumber);
      newForm.append('id', tin?.id);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          getKYCDoc();
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('TIN');
        }
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'reference') {
      newForm.append('id', reference?.id);
      newForm.append('fileName', idType);
      newForm.append('identificationNumber', postData?.identificationNumber);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          getKYCDoc();
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('reference');
        }
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }

    if (type === 'MERMAT') {
      newForm.append('fileName', postData?.fileName);
      newForm.append('identificationNumber', postData?.identificationNumber);
      const res = await httpPost(
        `/upload-any-document/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      }
    }

    if (type === 'ID') {
      newForm.append('id', id?.id);
      if (idType === 'interPass') {
        newForm.append('fileName', 'INTERNATIONAL_PASSPORT');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.status) {
          swal('Done', res?.message, 'success').then(() => {
            hideLoader();
            getKYCDoc();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'nationalId') {
        const dataUrl = await toPng(textRef.current);
        const blob = await fetch(dataUrl).then((res) => res.blob());
        newForm.append('identificationNumber', nin);
        const file = await new File([blob], 'text-image.png', {
          type: 'image/png',
        });
        newForm.append('fileName', 'NIN');
        newForm.append('file', image === null ? file : image);
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.status) {
          swal('Done', res?.message, 'success').then(() => {
            hideLoader();
            getKYCDoc();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'votersCard') {
        newForm.append('fileName', 'VOTERS_CARD');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.status) {
          swal('Done', res?.message, 'success').then(() => {
            hideLoader();
            getKYCDoc();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'driversLicence') {
        newForm.append('fileName', 'DRIVER_LICENSE');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.status) {
          swal('Done', res?.message, 'success').then(() => {
            hideLoader();
            getKYCDoc();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
    }
    setLoading(false);
    hideLoader();
  };

  /* const getBvnStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/isBvnLinked/${userDetails.userId}`
    );
    if (res.status) {
      // console.log('bvn', res);
      if (res.data) {
        setBvnStat(res?.data);
      } else {
        setBvnStat(null);
      }
    }
  }; */

  const getKYCDoc = async () => {
    const res = await httpPost(
      `/api/v1/kyc/requirementData/${userDetails.userId}`,
      {},
      customBaseUrl.kycUrl
    );
    if (res?.status) {
      // console.log('bvn', res);
      if (res?.data) {
        setDocs(res?.data);
        const email1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'EMAIL'
        )[0];
        const phone1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'PHONE_NUMBER'
        )[0];
        const bvn1 = res?.data?.tier2?.filter(
          (e) => e?.requirementType === 'BVN'
        )[0];
        const id2 = res?.data?.tier2?.filter(
          (e) => e?.requirementType === 'ID'
        )[0];
        const passport1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'PHOTO'
        )[0];
        const id1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'ID'
        )[0];
        const utility1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'UTILITY'
        )[0];
        const cac1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'CAC'
        )[0];
        const tin1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'TIN'
        )[0];
        const reference1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'REFERENCE'
        )[0];
        // console.log('bvn', bvn1);
        // console.log('bvnph', phone1);
        // console.log('bvnem', email1);
        setEmail(email1);
        setPhone(phone1);
        setBvn(bvn1);
        setPassport(passport1);
        setId(id1);
        setUtility(utility1);
        setCac(cac1);
        setTin(tin1);
        setNinId(id2);
        setReference(reference1);
      } else {
        console.log(res, reference, docs);
      }
    }
  };

  const getCacKyc = async () => {
    const profileId = localStorage.getItem('profileId');
    const id = localStorage.getItem('userId');
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/fetchAllBusinessKycData/${id}/${profileId}`
    );
    if (res?.status) {
      if (res?.data?.length < 1 && isCac) {
        setShowBusReg(true);
      }
      if (res?.data) {
        const tier1 = res?.data?.filter(
          (e) => e?.kycTier?.name === 'TIER_1'
        )[0];
        const tier2 = res?.data?.filter(
          (e) => e?.kycTier?.name === 'TIER_2'
        )[0];
        const tier3 = res?.data?.filter(
          (e) => e?.kycTier?.name === 'TIER_3'
        )[0];
        const tier4 = res?.data?.filter(
          (e) => e?.kycTier?.name === 'TIER_3'
        )[0];
        setCacTier1(tier1);
        setCacTier2(tier2);
        setCacTier3(tier3);
        setCacTier4(tier4);
      } else {
        console.log(res);
      }
    }
  };
  const getShareHolders = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/fetchAllShareHolders/${cacTier3?.businessRequirementId}`
    );
    if (res?.status) {
      if (res?.data) {
        setShareHolders(res?.data);
      } else {
        console.log(res);
      }
    }
  };
  const getDirectors = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/fetchAllDirectors/${cacTier3?.businessRequirementId}`
    );
    if (res?.status) {
      setDirectors(res?.data);
      setTeir3Status(res?.data[0]?.status === 'APPROVED');
    } else {
      console.log(res);
    }
  };
  const getDocuments = async () => {
    const profileId = localStorage.getItem('profileId');
    const id = localStorage.getItem('userId');
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/fetchAllBusinessDocuments/${id}/${profileId}`
    );
    if (res?.status) {
      setDocuments(res?.data);
    } else {
      console.log(res);
    }
  };

  const getStatus = () => {
    const shareStatus = {};
    const shareId = shareHolders[0]?.id;
    const dirId1 = directors[0]?.id;
    const dirId2 = directors[0]?.id;
    const uHouseS = documents?.filter(
      (e) => e?.shareHolder?.id === shareId && e?.docType === 'UTILITY-HOUSE'
    )[0];
    const sharePhoto = documents?.filter(
      (e) => e?.shareHolder?.id === shareId && e?.docType === 'PHOTO'
    )[0];
    const idShare = documents?.filter(
      (e) =>
        e?.shareHolder?.id === shareId &&
        (e?.docType === 'NIN' || e?.docType === 'NATIONAL_ID')
    )[0];
    const uHouseD1 = documents?.filter(
      (e) => e?.director?.id === dirId1 && e?.docType === 'UTILITY-HOUSE'
    )[0];
    const dirPhoto1 = documents?.filter(
      (e) => e?.director?.id === dirId1 && e?.docType === 'PHOTO'
    )[0];
    const idDir1 = documents?.filter(
      (e) =>
        e?.director?.id === dirId1 &&
        (e?.docType === 'NIN' || e?.docType === 'NATIONAL_ID')
    )[0];
    const uHouseD2 = documents?.filter(
      (e) => e?.director?.id === dirId2 && e?.docType === 'UTILITY-HOUSE'
    )[0];
    const dirPhoto2 = documents?.filter(
      (e) => e?.director?.id === dirId2 && e?.docType === 'PHOTO'
    )[0];
    const idDir2 = documents?.filter(
      (e) =>
        e?.director?.id === dirId2 &&
        (e?.docType === 'NIN' || e?.docType === 'NATIONAL_ID')
    )[0];
    shareStatus.sUtility = uHouseS?.status;
    shareStatus.sId = idShare?.status;
    shareStatus.sPhoto = sharePhoto?.status;
    shareStatus.dUtility1 = uHouseD1?.status;
    shareStatus.dId1 = idDir1?.status;
    shareStatus.dPhoto1 = dirPhoto1?.status;
    shareStatus.dUtility2 = uHouseD2?.status;
    shareStatus.dId2 = idDir2?.status;
    shareStatus.dPhoto2 = dirPhoto2?.status;
    console.log({ shareStatus });
    setOverviewStatus(shareStatus);
  };

  const getAddressStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/address/user/${userDetails.userId}`
    );
    if (res?.status) {
      if (res?.data) {
        setUserAddress(res.data === true ? res.data : res.data.validated);
      } else {
        setUserAddress(null);
      }
    }
  };
  const upgradeL2 = async () => {
    const profileId = localStorage.getItem('profileId');
    const id = localStorage.getItem('userId');
    await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/upgradeKycLevel2/${id}/${profileId}`
    );
  };

  const getUtilityBillStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/getUserKycData/utilityBill/${userDetails.userId}`
    );
    if (res?.status) {
      setUtilityBillStat(res?.data);
      console.log('utility>>', utilityBillStat);
    }
    return [];
  };

  /*  const getTransactionLevels = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/fetchAllTransactionLevels`
    );
    if (res.status) {
      settrnxLevels(res.data);
      console.log('transAll', trnxlevels);
      setLevel1(res?.data?.filter((e) => e?.tiers === 'tier1')[0]);
      setLevel2(res?.data?.filter((e) => e?.tiers === 'tier2')[0]);
      setLevel3(res?.data?.filter((e) => e?.tiers === 'tier3')[0]);
      setLevel4(res?.data?.filter((e) => e?.tiers === 'tier4')[0]);
    }
    return [];
  }; */
  useEffect(() => {
    //  getBvnStatus();
    getKYCDoc();
    getCacKyc();
  }, [reload, showAlert]);
  useEffect(() => {
    if (cacTier2?.status === 'APPROVED' && level < 2) {
      upgradeL2();
    }
  }, [cacTier2]);
  useEffect(() => {
    if (isCac && cacTier3?.businessRequirementId) {
      getDirectors();
      getShareHolders();
      getDocuments();
    }
  }, [cacTier3]);
  useEffect(() => {
    getStatus();
  }, [directors, shareHolders, documents]);
  // useEffect(() => {
  //   if (page === 3) setShowUploadMode(true);
  // }, [page]);
  return (
    <div>
      {page === 1 ? (
        <div className="tier" id="tier1">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 1</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  phone?.status === 'APPROVED' || email?.status === 'APPROVED'
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {phone?.status === 'APPROVED' || email?.status === 'APPROVED'
                  ? 'COMPLETED'
                  : 'NOT COMPLETED'}
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {!isCac ? (
                  <>
                    <tr className="">
                      <td className="p-3">Phone Number Verification</td>
                      <td>
                        <div
                          onClick={() => {
                            setShowAlertType('verifyPhone');
                            setShowAlert(true);
                          }}
                          className={`action ${
                            phone?.status === 'APPROVED'
                              ? 'btn disabled'
                              : 'btn'
                          } my-2`}
                        >
                          <img src={frontArrow} alt="" className="me-2 " />
                          <span>
                            {phone?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            phone?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        >
                          <img
                            src={phone?.status === 'APPROVED' ? check : pending}
                            alt=""
                            className={`me-2 ${
                              phone?.status === 'APPROVED'
                                ? 'status-true'
                                : 'status-pending'
                            }`}
                          />
                          <span>{phone?.status}</span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="p-3">Email Verification</td>
                      <td>
                        <div
                          onClick={() => {
                            setShowAlertType('verifyEmail');
                            setShowAlert(true);
                          }}
                          className={`action ${
                            email?.status === 'APPROVED'
                              ? 'btn disabled'
                              : 'btn'
                          } my-2`}
                        >
                          <img src={frontArrow} alt="" className="me-2" />
                          <span>
                            {' '}
                            {email?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            email?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        >
                          <img
                            src={email?.status === 'APPROVED' ? check : pending}
                            alt=""
                            className={`me-2 ${
                              email?.status === 'APPROVED'
                                ? 'status-true'
                                : 'status-pending'
                            }`}
                          />
                          <span>{email?.status}</span>
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr className="">
                      <td className="p-3">BUSINESS NAME</td>
                      <td>
                        <div>
                          <span>{cacTier1?.name}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            cacTier1?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        >
                          <img
                            src={
                              cacTier1?.status === 'APPROVED' ? check : pending
                            }
                            alt=""
                            className={`me-2 ${
                              cacTier1?.status === 'APPROVED'
                                ? 'status-true'
                                : 'status-pending'
                            }`}
                          />
                          <span>{cacTier1?.status}</span>
                        </div>
                      </td>
                    </tr>
                    {/* <tr className="">
                      <td className="p-3">Email</td>
                      <td colSpan={2}>
                        <div>
                          <span>{cacTier1?.email}</span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="p-3">PHONE NUMBER</td>
                      <td colSpan={2}>
                        <div>
                          <span>{cacTier1?.mobile}</span>
                        </div>
                      </td>
                    </tr> */}
                    <tr className="">
                      <td className="p-3">Phone Number Verification</td>
                      <td>
                        <span>{cacTier1?.mobile}</span>
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            setShowAlertType('verifyPhone');
                            setShowAlert(true);
                          }}
                          className={`action ${
                            phone?.status === 'APPROVED'
                              ? 'btn disabled'
                              : 'btn'
                          } my-2`}
                        >
                          <img src={frontArrow} alt="" className="me-2 " />
                          <span>
                            {phone?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            phone?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        >
                          <img
                            src={phone?.status === 'APPROVED' ? check : pending}
                            alt=""
                            className={`me-2 ${
                              phone?.status === 'APPROVED'
                                ? 'status-true'
                                : 'status-pending'
                            }`}
                          />
                          <span>{phone?.status}</span>
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="p-3">Email Verification</td>
                      <td>
                        <span>{cacTier1?.email}</span>
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            setShowAlertType('verifyEmail');
                            setShowAlert(true);
                          }}
                          className={`action ${
                            email?.status === 'APPROVED'
                              ? 'btn disabled'
                              : 'btn'
                          } my-2`}
                        >
                          <img src={frontArrow} alt="" className="me-2" />
                          <span>
                            {' '}
                            {email?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            email?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        >
                          <img
                            src={email?.status === 'APPROVED' ? check : pending}
                            alt=""
                            className={`me-2 ${
                              email?.status === 'APPROVED'
                                ? 'status-true'
                                : 'status-pending'
                            }`}
                          />
                          <span>{email?.status}</span>
                        </div>
                      </td>
                    </tr>
                    {/* <tr className="">
                      <td className="p-3">BUSINESS DESCRIPTION</td>
                      <td>
                        <div
                          onClick={() => {
                            setShowAlertType('verifyPhone');
                            setShowAlert(true);
                          }}
                          className={`my-2`}
                        >
                          <span>Description</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            phone?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        >
                          <span />
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td className="p-3">BUSINESS TYPE</td>
                      <td>
                        <select>
                          <option value="">Select business type</option>
                        </select>
                      </td>
                      <td>
                        <div>
                          <span />
                        </div>
                      </td>
                        </tr> */}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({phone?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{phone?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {phone?.singleTransactionLimit}){' '}
            </p>
          </div>
          {(isCac && cacTier1?.status === 'APPROVED') || !isCac ? (
            <div className="tier-foot mb-2">
              <div className="tier-foot-1">
                NB: Supported files for upload includes, jpeg, png, pdf
              </div>
              <div className="tier-foot-2 me-0">
                <div
                  className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
                  onClick={() => {
                    handlePage('previous');
                  }}
                >
                  <img className="me-2" src={backArrow} alt="" /> Previous
                </div>{' '}
                <div className="page mx-1">{page}</div>{' '}
                <div
                  className={`${
                    page === 4 &&
                    (phone?.status === 'APPROVED' ||
                      email?.status === 'APPROVED')
                      ? 'btn disabled'
                      : 'btn'
                  }`}
                  onClick={() => {
                    handlePage('next');
                  }}
                >
                  Next
                  <img className="ms-2" src={frontArrow} alt="" />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div style={{ height: 100 }} />
        </div>
      ) : page === 2 ? (
        <div className="tier" id="tier2">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 2</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  bvn?.status === 'APPROVED'
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >{!isCac ? (
                bvn?.status === 'APPROVED' &&
                (id?.status === 'APPROVED' || ninId?.status === 'APPROVED')
                  ? 'COMPLETED'
                  : 'NOT COMPLETED'
              ) : (
                bvn?.status === 'APPROVED' ? 'Completed' : 'Not Completed'
              )}             
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">BVN Validation</td>
                  <td>
                    <div
                      className={`action ${
                        bvn?.status === 'APPROVED' ||bvn?.status === 'UNDER_REVIEW' 
                        ? 'btn disabled' : 'btn'
                      } my-2`}
                      onClick={() => {
                        setShowAlertType('bvn');
                        setShowAlert(true);
                      }}
                    >
                      <img src={frontArrow} alt="" className="me-2" />
                      <span>
                        {bvn?.status === 'APPROVED' ? 'Verified' : 'Verify'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        bvn?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={bvn?.status === 'APPROVED' ? check : pending}
                        alt=""
                        className={`me-2 ${
                          bvn?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>{bvn?.status}</span>
                      <br />
                      <span style={{ color: 'red' }}>
                        {bvn?.status === 'REJECTED' ? bvn?.reason : ''}
                      </span>
                    </div>
                  </td>
                </tr>
                {!isCac ? (
                  <tr className="">
                    <td className="p-3">NIN Validation</td>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div ref={textRef}>
                          <input
                            placeholder="NIN"
                            type="text"
                            maxLength="11"
                            value={
                              nin ??
                              id?.verificationReference ??
                              ninId?.verificationReference
                            }
                            onChange={(e) => {
                              const onlyDigits = e.target.value.replace(
                                /\D/g,
                                ''
                              );
                              setNin(onlyDigits);
                            }}
                            disabled={
                              id?.status === 'APPROVED' ||
                              ninId?.status === 'APPROVED' ||
                              id?.status === 'UNDER_REVIEW' ||
                              ninId?.status === 'UNDER_REVIEW'
                            }
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => handleUploadDoc('ID', null)}
                            disabled={
                              id?.status === 'APPROVED' ||
                              ninId?.status === 'APPROVED' ||
                              id?.status === 'UNDER_REVIEW' ||
                              ninId?.status === 'UNDER_REVIEW'
                            }
                          >
                            {id?.status === 'APPROVED' ||
                            ninId?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          id?.status === 'APPROVED' ||
                          ninId?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }
                      >
                        <img
                          className={
                            id?.status === 'APPROVED' ||
                            ninId?.status === 'APPROVED'
                              ? 'status-true'
                              : 'status-pending'
                          }
                        />
                        <span>{id?.status ?? ninId?.status}</span>
                        <br />
                        <span style={{ color: 'red' }}>
                          {id?.status === 'REJECTED' ? id?.reason : ''}
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : ('')}
              </tbody>
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({bvn?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{bvn?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {bvn?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-1">
              NB: Supported files for upload includes, jpeg, png, pdf
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${page === 4 ? 'btn disabled' : 'btn'}`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : page === 3 ? (
        <div className="tier" id="tier3">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 3</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              {!isCac ? (
                <p
                className={`${
                  passport?.status === 'APPROVED' &&
                  (id?.status === 'APPROVED' || ninId?.status === 'APPROVED') &&
                  utility?.status === 'APPROVED'
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
                >
                  {((id?.status === 'APPROVED' || ninId?.status === 'APPROVED') &&
                  passport?.status === 'APPROVED' &&
                  utility?.status === 'APPROVED')
                  || teir3Status
                    ? 'Completed'
                    : 'Not Completed'}
                </p>
              ) : (
                <p
                  className={`${teir3Status ? 'info-2-b-true'
                      : ''
                  } px-3 py-1`}
                >
                  {teir3Status ? 'Completed' : 'Not Completed'}
                </p>
              )}
            </div>
          </div>
          <div className="table my-2">
            {!isCac ? (
              <table className="w-100">
                <thead>
                  <tr className="text-center ">
                    <th className="p-2 w-50">Kyc Requirement</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="p-3">Passport Photograph</td>
                    <td>
                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('photograph');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>
                          {passport?.status === 'APPROVED'
                            ? 'Uploaded'
                            : 'Upload'}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          passport?.status === 'APPROVED'
                            ? 'status-true'
                            : passport?.status === 'REJECTED'
                            ? 'status-false'
                            : 'status-pending'
                        }
                      >
                        <img
                          src={
                            passport?.status === 'APPROVED'
                              ? check
                              : passport?.status === 'REJECTED'
                              ? rejected
                              : pending
                          }
                          alt=""
                          className="me-2"
                        />
                        <span>{passport?.status}</span>
                        <br />
                      <span style={{ color: 'red' }}>
                        {passport?.status === 'REJECTED' ? bvn?.reason : ''}
                      </span>
                      </div>
                    </td>
                  </tr>
                  <tr className="">
                    <td className="p-3">NIN</td>
                    <td className="d-flex flex-column">
                      <div
                        className={`action ${
                          id?.status === 'APPROVED' ||
                          ninId?.status === 'APPROVED' ||
                          id?.status === 'UNDER_REVIEW' ||
                          ninId?.status === 'UNDER_REVIEW'
                            ? 'btn disabled'
                            : 'btn'
                        } my-2`}
                        onClick={() => {
                          hideModal(true);
                          setName('ID');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>
                          {id?.status === 'APPROVED' ? 'Uploaded' : 'Upload'}
                        </span>
                      </div>
                      {/*  <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('driverLicense');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>Upload</span>
                      </div>

                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('nationalId');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>Upload</span>
                      </div>
                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('voterCard');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>Upload</span>
                      </div> */}
                    </td>
                    <td>
                      {/* <div className="status mb-3">
                        <img src={check} alt="" className="me-2" />
                        <span>{kycStatus?.internationalPassport?.status}</span>
                      </div>
                      <div className="status mb-3">
                        <img src={check} alt="" className="me-2" />
                        <span>{kycStatus?.driverLicense?.status}</span>
                      </div> */}
                      <div
                        className={
                          id?.status === 'APPROVED' || ninId?.status === 'APPROVED'
                            ? 'status-true'
                            : id?.status === 'REJECTED'
                            ? 'status-false'
                            : 'status-pending'
                        }
                      >
                        <img
                          src={
                            id?.status === 'APPROVED' ||
                            ninId?.status === 'APPROVED'
                              ? check
                              : id?.status === 'REJECTED'
                              ? rejected
                              : pending
                          }
                          alt=""
                          className="me-2"
                        />
                        <span>{id?.status || ninId?.status}</span>
                        <br />
                        <span style={{ color: 'red' }}>
                          {id?.status === 'REJECTED' ? id?.reason : ''}
                        </span>
                      </div>
                      {/* <div className="status">
                        <img src={check} alt="" className="me-2" />
                        <span>{kycStatus?.voterCard?.status}</span>
                      </div> */}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="p-3">
                      <div>
                        <p>Utility bill</p>
                        <p>
                          (Any TV subscription bills, any internet subscription
                          bills, bank statement)
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('utility');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>
                          {usersUtilityBill?.adminStatus === 'APPROVED'
                            ? 'Uploaded'
                            : 'Upload'}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          usersUtilityBill?.adminStatus === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }
                      >
                        <img
                          src={
                            usersUtilityBill?.adminStatus === 'APPROVED'
                              ? check
                              : usersUtilityBill?.adminStatus === 'REJECTED'
                              ? rejected
                              : pending
                          }
                          alt=""
                          className="me-2"
                        />
                        <span>{utility?.status}</span>
                        <br />
                      <span style={{ color: 'red' }}>
                        {utility?.status === 'REJECTED' ? utility?.reason : ''}
                      </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <>
                {isSummary ? (
                  <div className="table my-2">
                    <table className="w-100">
                      <thead>
                        <tr className="text-center ">
                          <th className="p-2 w-1/3">Document</th>
                          <th className="p-2 w-1/3">Director 1</th>
                          <th className="p-2 w-1/3">Director 2</th>
                          <th className="p-2 w-1/3">Shareholder 2</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr className="">
                            <td className="p-3">BVN</td>
                            <td>
                              <div
                                onClick={() => {
                                  setShowAlertType('verifyPhone');
                                  setShowAlert(true);
                                }}
                                className={`action ${
                                  phone?.status === 'APPROVED'
                                    ? 'btn disabled'
                                    : 'btn'
                                } my-2`}
                              >
                                <img
                                  src={frontArrow}
                                  alt=""
                                  className="me-2 "
                                />
                                <span>
                                  {overviewStatus?.dBvn?.status === 'APPROVED'
                                    ? 'Verified'
                                    : 'Verify'}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div
                                className={
                                  overviewStatus?.status === 'APPROVED'
                                    ? 'status-true'
                                    : 'status-pending'
                                }
                              >
                                <img
                                  src={
                                    phone?.status === 'APPROVED'
                                      ? check
                                      : pending
                                  }
                                  alt=""
                                  className={`me-2 ${
                                    phone?.status === 'APPROVED'
                                      ? 'status-true'
                                      : 'status-pending'
                                  }`}
                                />
                                <span>{overviewStatus?.idDir1}</span>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="p-3">NIN</td>
                            <td>
                              <div
                                onClick={() => {
                                  setShowAlertType('verifyEmail');
                                  setShowAlert(true);
                                }}
                                className={`action ${
                                  overviewStatus?.dId1 === 'APPROVED'
                                    ? 'btn disabled'
                                    : 'btn'
                                } my-2`}
                              >
                                <img
                                  src={
                                    overviewStatus?.dId1 === 'APPROVED'
                                      ? check
                                      : pending
                                  }
                                  alt=""
                                  className={`me-2 ${
                                    overviewStatus?.dId1 === 'APPROVED'
                                      ? 'status-true'
                                      : 'status-pending'
                                  }`}
                                />
                                <span>{overviewStatus?.dId1}</span>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`action ${
                                  overviewStatus?.dId2 === 'APPROVED'
                                    ? 'btn disabled'
                                    : 'btn'
                                } my-2`}
                              >
                                <img
                                  src={
                                    overviewStatus?.dId2 === 'APPROVED'
                                      ? check
                                      : pending
                                  }
                                  alt=""
                                  className={`me-2 ${
                                    overviewStatus?.dId2 === 'APPROVED'
                                      ? 'status-true'
                                      : 'status-pending'
                                  }`}
                                />
                                <span>{overviewStatus?.dId2}</span>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`action ${
                                  overviewStatus?.sId === 'APPROVED'
                                    ? 'btn disabled'
                                    : 'btn'
                                } my-2`}
                              >
                                <img
                                  src={
                                    overviewStatus?.sId === 'APPROVED'
                                      ? check
                                      : pending
                                  }
                                  alt=""
                                  className={`me-2 ${
                                    overviewStatus?.sId === 'APPROVED'
                                      ? 'status-true'
                                      : 'status-pending'
                                  }`}
                                />
                                <span>{overviewStatus?.sId}</span>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="p-3">Passport</td>
                            <td>adm</td>
                            <td>staus</td>
                            <td>staus</td>
                          </tr>
                          <tr className="">
                            <td className="p-3">Utilty</td>
                            <td>adm</td>
                            <td>staus</td>
                            <td>staus</td>
                          </tr>
                        </>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                    <div style={{ flexDirection: 'row', textAlign: 'left' }}>
                      <button
                        type="button"
                        className="btn"
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyItems: 'center',
                          background: viewShareholders ? '' : '#FF6700',
                          borderWidth: '2px',
                          borderColor: viewShareholders ? 'gray' : '#FF6700',
                          color: viewShareholders ? '#000' : '#fff',
                        }}
                        onClick={() => setViewShareholders(false)}
                      >
                        DIRECTORS
                      </button>
                      {/* <button
                        type="button"
                        className="btn"
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyItems: 'center',
                          background: viewShareholders ? '#FF6700' : '',
                          borderWidth: '2px',
                          borderColor: viewShareholders ? '#FF6700' : 'gray',
                          color: viewShareholders ? '#fff' : '#000',
                        }}
                        onClick={() => setViewShareholders(true)}
                      >
                        SHAREHOLDERS
                      </button> */}
                    </div>
                    {viewShareholders ? (
                      <UploadShareHolder
                        sData={
                          shareHolders?.length > 0 ? shareHolders[sPage] : {}
                        }
                        shareHolders={shareHolders}
                        userId={userDetails.userId}
                        businessId={cacTier3?.businessRequirementId}
                        sPage={sPage}
                        setSPage={setSPage}
                        getShareHolders={getShareHolders}
                        documents={documents}
                        getDocuments={getDocuments}
                      />
                    ) : (
                      <UploadDirectors
                        sData={directors?.length > 0 ? directors[dPage] : {}}
                        directors={directors}
                        userId={userDetails.userId}
                        businessId={cacTier3?.businessRequirementId}
                        sPage={dPage}
                        setSPage={setDPage}
                        getDirectors={getDirectors}
                        documents={documents}
                        getDocuments={getDocuments}
                        setDirStatus={setDirStatus}
                        dirStatus={dirStatus}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({id?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{id?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {id?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="col">
              <div className="tier-foot-1">
                <p className="limit-info fw-bold my-3"> NB: </p>
                <p className="limit-info fw-bold my-3">
                  For Business users – sole proprietorship:
                </p>
                <p> Upload Any 1 Government ID for the director. </p>
                <p className="limit-info fw-bold my-3">
                  For Business users – LTD/PLC and above:
                </p>
                <p>
                  Upload at least 2 Government ID of the directors - Scanned to
                  one file as PDF and upload once.
                </p>
              </div>
              <div className="tier-foot-1">
                Supported files for upload includes, jpeg, png, pdf
              </div>
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${page !== 4 && isCac ? 'btn' : 'btn disabled'}`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : page === 4 ? (
        <div className="tier" id="tier4">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 4</p>
            <div>
              <a
                href="https://waya-business-acount-opening-form.s3.eu-west-2.amazonaws.com/BUSINESS+ACCOUNT+OPENING+REFERENCE+FORM_page-0001+(1).pdf"
                download
                target="_blank"
                rel="noreferrer"
              >
                Click here to download bank reference form template
              </a>
            </div>
            <div className="info-2">
              <p className="info-2-t">status</p>
              {!isCac ? (
                <p
                  className={`${
                    cac?.status === 'APPROVED' && tin?.status === 'APPROVED'
                      ? 'info-2-b-true'
                      : 'info-2-b-false'
                  } px-3 py-1`}
                >
                  {cac?.status === 'APPROVED' &&
                  tin?.status === 'APPROVED' &&
                  reference?.status === 'APPROVED'
                    ? 'Completed'
                    : 'Not Completed'}
                </p>
              ) : (
                <p
                  className={`${
                    tier4Status
                      ? 'info-2-b-true'
                      : ''
                  } px-3 py-1`}
                >
                  {tier4Status ? 'Completed' : 'Not Completed'}
                </p>
              )}
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              {!isCac ? (
                <tbody>
                  <tr className="">
                    <td className="p-3">CAC Certificate </td>
                    <td>
                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('cac');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>Upload</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          cac?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }
                      >
                        <img
                          src={
                            cac?.status === 'APPROVED'
                              ? check
                              : cac?.status === 'REJECTED'
                              ? rejected
                              : pending
                          }
                          alt=""
                          className="me-2"
                        />
                        <span>{cac?.status}</span>
                      </div>
                    </td>
                  </tr>
                  <tr className="">
                    <td className="p-3">Tax Indentification Number</td>
                    <td>
                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('tin');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>Upload</span>
                      </div>
                      {/* <div>
                        <Input
                          type="number"
                          style={{ width: 100 }}
                          placeholder="TIN"
                          value={tin}
                          onChange={(e) => setTin(e.target.value)}
                        />
                        <Button
                          onClick={handleTin}
                          shape="circle"
                          icon={<UploadOutlined />}
                          size="small"
                        />
                      </div> */}
                    </td>
                    <td>
                      {/* <div className="status">
                        <img src={check} alt="" className="me-2" />
                        <span>Approved</span>
                      </div> */}
                      <div
                        className={
                          tin?.status === 'APPROVED'
                            ? 'status-true'
                            : tin?.status === 'REJECTED'
                            ? 'status-false'
                            : 'status-pending'
                        }
                      >
                        <img
                          src={
                            tin?.status === 'APPROVED'
                              ? check
                              : tin?.status === 'REJECTED'
                              ? rejected
                              : pending
                          }
                          alt=""
                          className="me-2"
                        />
                        <span>{tin?.status}</span>
                      </div>
                    </td>
                  </tr>
                  <tr className="">
                    <td className="p-3">Reference</td>
                    <td>
                      <div
                        className="action btn my-2"
                        onClick={() => {
                          hideModal(true);
                          setName('reference');
                        }}
                      >
                        <img src={upload} alt="" className="me-2" />
                        <span>Upload</span>
                      </div>
                    </td>
                    <td>
                      {/* <div className="status">
                        <img src={check} alt="" className="me-2" />
                        <span>Approved</span>
                      </div> */}
                      <div
                        className={
                          reference?.status !== 'APPROVED'
                            ? 'status-pending'
                            : 'status-true'
                        }
                      >
                        <img
                          src={
                            reference?.status !== 'APPROVED' ? pending : check
                          }
                          alt=""
                          className="me-2"
                        />
                        <span>{reference?.status}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <CacTier4
                  userId={userDetails?.userId}
                  businessId={cacTier3?.businessRequirementId}
                  getDocuments={getDocuments}
                  documents={documents}
                  setTier4Status={setTier4Status}
                />
              )}
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({tin?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{tin?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {tin?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-1">
              NB: Supported files for upload includes, jpeg, png, pdf
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${page === 4 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : (
        ''
      )}

      {showModal ? (
        <UploadModal
          hideModal={hideModal}
          showModal={showModal}
          handleUploadDoc={handleUploadDoc}
          name={name}
          postData={postData}
          setPostData={setPostData}
          loading={loading}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          setIdType={setIdType}
          idType={idType}
        />
      ) : (
        ''
      )}
      {showAlert ? (
        <VerifyEmailAndPhone
          showAlert={showAlert}
          type={showAlertType}
          uploadMode={uploadMode}
          setShowAlert={setShowAlert}
          setShowMode={setShowUploadMode}
        />
      ) : (
        ''
      )}
      {showUploadMode ? (
        <KYCUploadMode
          hideModal={setShowUploadMode}
          showModal={showUploadMode}
          uploadMode={uploadMode}
          setUploadMode={setUploadMode}
        />
      ) : (
        ''
      )}
      {showBusReg ? (
        <BusinessReg
          hideModal={setShowBusReg}
          showModal={showBusReg}
          setReload={setReload}
          reload={reload}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default UploadTier;
