import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Switch } from "@mui/material";
import "./styles.scss";
import { DropDownFilter } from "../../../../components/UI/DropDownFilter";
import { addBank, addBankToTerminal, bankList, verifyMerchantBankAccount } from "../../../../plugins/urls";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Slide, toast } from "react-toastify";
import _ from "lodash";
import { getUser } from "../../../../utilities/objectOperations";
import { blurHandler } from "../../../../utilities/handlers";

export const AddBankToTerminal = ({ open, onClose, setOpen, terminals, accountsList }) => {
  const [formData, setFormData] = useState({
    accountNumber: null,
    accountName: "",
  });
  const [optionLists, setoptionLists] = useState([]);
  const user = getUser();

  const [sel, setSel] = useState("Select terminal");
  const [selAccount, setSelAccount] = useState("Select account");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      setFormData((fd) => ({ ...fd, [name]: checked }));
    } else {
      setFormData((fd) => ({ ...fd, [name]: value }));
    }
  };

  const getAllBank = () => {
    axios.get(bankList).then((res) => {
      setoptionLists(res?.data?.respBody);
    });
  };
  const { accountNumber } = formData;

  const verifyAcctNumber = (data) => {
    axios.post(verifyMerchantBankAccount, data).then((res) => {
      if (res.status === 200 && res.data.respCode === 0) {
        const bankName = res.data.respBody;
        setFormData((dt) => ({
          ...dt,
          accountName: bankName?.substring(bankName.indexOf("|") + 1),
        }));
      }
    });
  };

//   useEffect(() => {
//     _.isEmpty(optionLists) && getAllBank();
//     if (sel && accountNumber && accountNumber.length === 10) {
//       const bank = optionLists.find(({ bankName }) => bankName === sel);
//       verifyAcctNumber({ accountNumber, bankCode: bank?.bankCode });
//     } else {
//       setFormData((dt) => ({
//         ...dt,
//         accountName: "",
//       }));
//     }
//   }, [accountNumber, sel]);

  const handleClick = (e) => {
    e.preventDefault();
    if (sel && selAccount) {
      setLoading(true);
      const reqBody = {        
      };
      axios
        .post(`${addBankToTerminal}?bankId=${selAccount}&terminalId=${sel}`)
        .then((res) => {
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
          setOpen(false);
          setFormData({});
        });
    } else {
      toast.error(
        "All fields are required",
        {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
    }
  };

  const validations = {
    accountNumber: { required: true, maxLength: 10, minLength: 9 },
    accountName: {
      required: true,
      pattern: /^([^0-9]*)$/,
    },
  };

  return (
    <Dialog open={open} onClose={onClose} className={"addBank__dialog"}>
      <DialogTitle>{"ADD BANK ACCOUNT FOR SETTLEMENT"}</DialogTitle>
      <DialogContent>
        <form>
          <div className="addBank__dialog_dropdown">
            <label>Select terminal</label>
            <select
              onChange={(e) => setSel(e.target.value)}
              value={sel}
            >
              <option>Select terminal</option>
              {terminals?.map((e) =>
                <option value={e?.id} key={e?.id}>
                  {e?.actualTerminalName}
                </option>
              )}
            </select>
            {/* <DropDownFilter
              sel={sel}
              setSel={setSel}
              optionList={terminals}
              keyVal={{ key: "id", val: "id" }}
            /> */}
          </div>
          <div className="addBank__dialog_dropdown">
            <label>Select Terminal</label>
            <select
              onChange={(e) => setSelAccount(e.target.value)}
              value={selAccount}
            >
              <option>Select account</option>
              {accountsList?.map((e) =>
                <option value={e?.id} key={e?.id}>
                  {e?.accountNumber}-{e?.bankName}
                </option>
              )}
            </select>
            {/* <DropDownFilter
              sel={selAccount}
              setSel={setSelAccount}
              optionList={accountsList}
              keyVal={{ key: "id", val: "id" }}
            /> */}
          </div>

          <div className="btn-group">
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              {"Cancel"}
            </button>
            <button
              className="button"
              disabled={!selAccount || !sel}
              onClick={handleClick}
            >
              {"Add bank Account"}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
